import { createContext, useContext } from 'react';
import { useRouter } from 'next/router'
import { setCookies } from 'cookies-next'

const AppContext = createContext();

export function AppWrapper({ children }) {
    const router = useRouter();
    let sharedState = {
        utm_campaign: router.query.utm_campaign ? router.query.utm_campaign : '',
        utm_medium: router.query.utm_medium ? router.query.utm_medium : '',
        utm_term: router.query.utm_term ? router.query.utm_term : '',
        utm_source: router.query.utm_source ? router.query.utm_source : '',
        utm_content: router.query.utm_content ? router.query.utm_content : ''
    }

    if(router.query.utm_campaign ||
        router.query.utm_medium ||
        router.query.utm_term ||
        router.query.utm_source ||
        router.query.utm_content){
            setCookies('elmlead', sharedState);
        }

    return (
        <AppContext.Provider value={sharedState}>
        {children}
        </AppContext.Provider>
    );
}

export function useAppContext() {
  return useContext(AppContext);
}
